import React, { useState } from 'react';
import Layout from './Layout';
import axios from 'axios';
const API_URL = process.env.GATSBY_API_URL || 'http://localhost:9000';

const Driver = ({ onChange, onAddDriver }) => {
  const [sent, setSent] = useState(false);
  const [error, setError] = useState(false);
  const [info, setInfo] = useState({
    name: '',
    dob: '',
    mileage: '',
    licenseNumber: '',
  });

  const onInfoChange = (key, value) => {
    setInfo({ ...info, [key]: value });
    onChange({ ...info, [key]: value });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    if (!info.name || !info.dob || !info.mileage) {
      return alert('Please add driver name, dob, and mileage.');
    }
    onAddDriver();
    setInfo({
      name: '',
      dob: '',
      mileage: '',
      licenseNumber: '',
    });
  };

  return (
    <form
      style={{
        display: 'flex',
        flex: 1,
        flexDirection: 'row',
        flexWrap: 'wrap',
      }}
    >
      <label style={{ width: '30%', marginTop: 0, marginRight: 20 }}>
        First & Last Name*
        <input
          type="text"
          name="name"
          id="name"
          value={info.name}
          onChange={(e) => onInfoChange('name', e.target.value)}
        />
      </label>
      <label style={{ width: '30%', marginTop: 0, marginRight: 20 }}>
        Date of Birth*
        <input
          type="date"
          name="dob"
          id="dob"
          value={info.dob}
          onChange={(e) => onInfoChange('dob', e.target.value)}
        />
      </label>
      <label
        style={{ width: '30%', minWidth: 300, marginTop: 0, marginRight: 20 }}
      >
        Annual Mileage*
        <input
          type="text"
          name="mileage"
          id="mileage"
          value={info.mileage}
          onChange={(e) => onInfoChange('mileage', e.target.value)}
        />
      </label>
      <label
        style={{
          width: '30%',
          minWidth: 300,
          marginTop: 0,
          marginRight: 20,
        }}
      >
        License Number
        <input
          type="text"
          name="licenseNumber"
          id="licenseNumber"
          value={info.licenseNumber}
          onChange={(e) => onInfoChange('licenseNumber', e.target.value)}
        />
      </label>
      <div style={{ width: '100%' }}>
        <button onClick={onSubmit}>Save Driver</button>
      </div>
    </form>
  );
};

export default Driver;
