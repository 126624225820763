import React, { useState } from 'react';
import Layout from '../components/Layout';
import axios from 'axios';
const API_URL = process.env.GATSBY_API_URL || 'http://localhost:9000';

const Personal = ({
  onChange,
  showAddress = true,
  showOccupation = true,
  showComments = false,
}) => {
  const [sent, setSent] = useState(false);
  const [error, setError] = useState(false);
  const [info, setInfo] = useState({
    name: '',
    address: '',
    city: '',
    state: '',
    zip: '',
    email: '',
    phone: '',
    occupation: '',
    comments: '',
  });

  const onInfoChange = (key, value) => {
    setInfo({ ...info, [key]: value });
    onChange({ ...info, [key]: value });
  };

  const onSend = async () => {
    try {
      setSent(true);
      //   const res = await axios.post(`${API_URL}/contact`, {
      //     name,
      //     email,
      //     subject,
      //     message,
      //   });
    } catch (err) {
      console.log(err);
      setSent(true);
      setError(true);
    }
  };

  return (
    <form
      style={{
        display: 'flex',
        flex: 1,
        flexDirection: 'row',
        flexWrap: 'wrap',
      }}
    >
      <label style={{ width: '100%', marginTop: 0, marginRight: 20 }}>
        First & Last Name*
        <input
          type="text"
          name="name"
          id="name"
          onChange={(e) => onInfoChange('name', e.target.value)}
        />
      </label>
      {showAddress && (
        <>
          <label style={{ width: '100%', marginTop: 0, marginRight: 20 }}>
            Street Address*
            <input
              type="text"
              name="address"
              id="address"
              onChange={(e) => onInfoChange('address', e.target.value)}
            />
          </label>
          <label
            style={{
              width: '30%',
              minWidth: 300,
              marginTop: 0,
              marginRight: 20,
            }}
          >
            City*
            <input
              type="text"
              name="city"
              id="city"
              onChange={(e) => onInfoChange('city', e.target.value)}
            />
          </label>
          <label
            style={{
              width: '30%',
              minWidth: 300,
              marginTop: 0,
              marginRight: 20,
            }}
          >
            State*
            <input
              type="text"
              name="state"
              id="state"
              onChange={(e) => onInfoChange('state', e.target.value)}
            />
          </label>
          <label
            style={{
              width: '30%',
              minWidth: 300,
              marginTop: 0,
              marginRight: 20,
            }}
          >
            Zip Code*
            <input
              type="text"
              name="zip"
              id="zip"
              onChange={(e) => onInfoChange('zip', e.target.value)}
            />
          </label>
        </>
      )}
      <label style={{ width: '45%', marginTop: 0, marginRight: 20 }}>
        Email*
        <input
          type="text"
          name="email"
          id="email"
          onChange={(e) => onInfoChange('email', e.target.value)}
        />
      </label>
      <label style={{ width: '45%', marginTop: 0, marginRight: 20 }}>
        Phone*
        <input
          type="text"
          style={{ minWidth: 350 }}
          name="phone"
          id="phone"
          onChange={(e) => onInfoChange('phone', e.target.value)}
        />
      </label>
      {showOccupation && (
        <label style={{ width: '45%', marginTop: 0, marginRight: 20 }}>
          Occupation*
          <input
            type="text"
            style={{ minWidth: 350 }}
            name="occupation"
            id="occupation"
            onChange={(e) => onInfoChange('occupation', e.target.value)}
          />
        </label>
      )}
      {showComments && (
        <label style={{ width: '100%', marginTop: 0, marginRight: 20 }}>
          Comments
          <br />
          <textarea
            name="anything-else"
            id="anything-else"
            placeholder="Comments or concerns?"
            rows="6"
            onChange={(e) => onInfoChange('comments', e.target.value)}
          ></textarea>
        </label>
      )}
    </form>
  );
};

export default Personal;
