import React, { useState } from 'react';
import Layout from './Layout';
import axios from 'axios';
const API_URL = process.env.GATSBY_API_URL || 'http://localhost:9000';

const Vehicle = ({ onChange, onAddVehicle, type = 'Vehicle' }) => {
  const [sent, setSent] = useState(false);
  const [error, setError] = useState(false);
  const [info, setInfo] = useState({
    type,
    year: '',
    make: '',
    model: '',
    vin: '',
    value: '',
    uses: { business: false, pleasure: false, school: false, work: false },
    purchaseDate: '',
    annualMileage: '<5000',
  });

  const onInfoChange = (key, value) => {
    setInfo({ ...info, [key]: value });
    onChange({ ...info, [key]: value });
  };

  const onUsesChange = (key, value) => {
    setInfo({ ...info, uses: { ...info.uses, [key]: value } });
    onChange({ ...info, uses: { ...info.uses, [key]: value } });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    if (!info.year || !info.make || !info.model) {
      return alert('Please add driver year, make, and model.');
    }
    onAddVehicle();
    setInfo({
      year: '',
      make: '',
      model: '',
      vin: '',
      watercraftValue: '',
      uses: { business: false, pleasure: false, school: false, work: false },
      purchaseDate: '',
      annualMileage: '',
    });
  };

  return (
    <form
      style={{
        display: 'flex',
        flex: 1,
        flexDirection: 'row',
        flexWrap: 'wrap',
      }}
    >
      <label style={{ width: '30%', marginTop: 0, marginRight: 20 }}>
        Year*
        <input
          type="text"
          name="year"
          id="year"
          value={info.year}
          onChange={(e) => onInfoChange('year', e.target.value)}
        />
      </label>
      <label style={{ width: '30%', marginTop: 0, marginRight: 20 }}>
        Make*
        <input
          type="text"
          name="make"
          id="make"
          value={info.make}
          onChange={(e) => onInfoChange('make', e.target.value)}
        />
      </label>
      <label
        style={{ width: '30%', minWidth: 300, marginTop: 0, marginRight: 20 }}
      >
        Model*
        <input
          type="text"
          name="model"
          id="model"
          value={info.model}
          onChange={(e) => onInfoChange('model', e.target.value)}
        />
      </label>
      <label
        style={{
          width: '30%',
          minWidth: 300,
          marginTop: 0,
          marginRight: 20,
        }}
      >
        {type == 'Boat' ? 'Hull ID' : 'VIN'}
        <input
          type="text"
          name="vin"
          id="vin"
          value={info.vin}
          onChange={(e) => onInfoChange('vin', e.target.value)}
        />
      </label>
      <label style={{ width: '30%', marginTop: 0, marginRight: 20 }}>
        Purchase Date
        <input
          type="date"
          style={{ minWidth: 350 }}
          name="purchaseDate"
          id="purchaseDate"
          value={info.purchaseDate}
          onChange={(e) => onInfoChange('purchaseDate', e.target.value)}
        />
      </label>
      {type != 'Boat' && (
        <label style={{ width: '30%', marginTop: 0, marginRight: 20 }}>
          Annual Mileage
          <select
            onChange={(e) => onInfoChange('annualMileage', e.target.value)}
          >
            <option value="<5000">Less than 5,000</option>
            <option value="<7500">Less than 7,500</option>
            <option value="<10000">Less than 10,000</option>
            <option value=">10000">More than 10,000</option>
          </select>
        </label>
      )}
      {type != 'Vehicle' && (
        <label
          style={{
            width: '30%',
            minWidth: 300,
            marginTop: 0,
            marginRight: 20,
          }}
        >
          {type} Value
          <input
            type="text"
            name="watercraftValue"
            id="watercraftValue"
            value={info.watercraftValue}
            onChange={(e) => onInfoChange('watercraftValue', e.target.value)}
          />
        </label>
      )}
      {type == 'Vehicle' && (
        <label
          style={{ width: '45%', minWidth: 300, marginTop: 0, marginRight: 20 }}
        >
          Uses
          <br />
          <input
            type="checkbox"
            id="business"
            name="business"
            onChange={(e) => onUsesChange('business', e.target.value)}
          />
          <label htmlFor="business">Business</label>
          <input
            type="checkbox"
            id="pleasure"
            name="pleasure"
            onChange={(e) => onUsesChange('pleasure', e.target.value)}
          />
          <label htmlFor="pleasure">Pleasure</label>
          <input
            type="checkbox"
            id="school"
            name="school"
            onChange={(e) => onUsesChange('school', e.target.value)}
          />
          <label htmlFor="school">School</label>
          <input
            type="checkbox"
            id="work"
            name="work"
            onChange={(e) => onUsesChange('work', e.target.value)}
          />
          <label htmlFor="work">Work</label>
        </label>
      )}
      <div style={{ width: '100%' }}>
        <button onClick={onSubmit}>Save {type ? type : 'Vehicle'}</button>
      </div>
    </form>
  );
};

export default Vehicle;
