import React, { useState } from 'react';
import Layout from './Layout';
import axios from 'axios';
const API_URL = process.env.GATSBY_API_URL || 'http://localhost:9000';

const Coverage = ({ onChange }) => {
  const [sent, setSent] = useState(false);
  const [error, setError] = useState(false);
  const [info, setInfo] = useState({
    currentInsuranceCompany: '',
    currentInsuranceExpiration: '',
    desiredCoverage: '',
    desiredEffectiveDate: '',
    coverageOptions: {
      gap: false,
      windshield: false,
      rental: false,
      towing: false,
    },
  });

  const onInfoChange = (key, value) => {
    setInfo({ ...info, [key]: value });
    onChange({ ...info, [key]: value });
  };

  const onOptionsChange = (key, value) => {
    setInfo({
      ...info,
      coverageOptions: { ...info.coverageOptions, [key]: value },
    });
    onChange({
      ...info,
      coverageOptions: { ...info.coverageOptions, [key]: value },
    });
  };

  return (
    <form
      style={{
        display: 'flex',
        flex: 1,
        flexDirection: 'row',
        flexWrap: 'wrap',
      }}
    >
      <label style={{ width: '45%', marginTop: 0, marginRight: 20 }}>
        Current Insurance Company
        <input
          type="text"
          name="currentInsuranceCompany"
          id="currentInsuranceCompany"
          onChange={(e) =>
            onInfoChange('currentInsuranceCompany', e.target.value)
          }
        />
      </label>
      <label style={{ width: '45%', marginTop: 0, marginRight: 20 }}>
        Current Insurance Expiration*
        <input
          type="date"
          name="currentInsuranceExpiration"
          id="currentInsuranceExpiration"
          onChange={(e) =>
            onInfoChange('currentInsuranceExpiration', e.target.value)
          }
        />
      </label>
      <label style={{ width: '100%', marginTop: 0, marginRight: 20 }}>
        Desired Effective Coverage Date
        <input
          type="date"
          name="desiredEffectiveDate"
          id="desiredEffectiveDate"
          onChange={(e) => onInfoChange('desiredEffectiveDate', e.target.value)}
        />
      </label>
      <label style={{ width: '45%', marginTop: 0, marginRight: 20 }}>
        Desired Coverage <br />
        <input
          type="radio"
          id="best"
          name="ownership"
          onChange={() => onInfoChange('desiredCoverage', 'best')}
        />
        <label htmlFor="best">Best Coverage</label>
        <input
          type="radio"
          id="lowest"
          name="ownership"
          onChange={() => onInfoChange('desiredCoverage', 'lowest-deductible')}
        />
        <label htmlFor="lowest">Lowest Deductible</label>
        <input
          type="radio"
          id="lowestRate"
          name="ownership"
          onChange={() => onInfoChange('desiredCoverage', 'lowest-rate')}
        />
        <label htmlFor="lowestRate">Lowest Rate</label>
        <input
          type="radio"
          id="stateMinimum"
          name="ownership"
          onChange={() => onInfoChange('desiredCoverage', 'state-minimum')}
        />
        <label htmlFor="stateMinimum">State Minimum</label>
      </label>

      <label
        style={{ width: '45%', minWidth: 300, marginTop: 0, marginRight: 20 }}
      >
        Coverage Options
        <br />
        <input
          type="checkbox"
          id="gap"
          name="gap"
          onChange={(e) => onOptionsChange('gap', e.target.value)}
        />
        <label htmlFor="gap">GAP / Replacement Cost</label>
        <input
          type="checkbox"
          id="windshield"
          name="windshield"
          onChange={(e) => onOptionsChange('windshield', e.target.value)}
        />
        <label htmlFor="windshield">Glass / Windshield</label>
        <input
          type="checkbox"
          id="rental"
          name="rental"
          onChange={(e) => onOptionsChange('rental', e.target.value)}
        />
        <label htmlFor="rental">Rental reimbursements</label>
        <input
          type="checkbox"
          id="towing"
          name="towing"
          onChange={(e) => onOptionsChange('towing', e.target.value)}
        />
        <label htmlFor="towing">Towing & roadside assistance</label>
      </label>
    </form>
  );
};

export default Coverage;
